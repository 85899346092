
a {
    color: #fff !important;
    transition: all 0.3s;
    border-bottom: 1px solid #fff;
    &:focus,
    &:hover {
        background-color: #fff;
        color: var(--v-primary-base) !important;
    }
}


// main-visual
.main-visual {
    width: 100%;
    height: 400px;
    position: relative;
    &__img {
        height: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .main-visual {
        height: calc(100vh - 120px);
    }
}

// Scrolldown
.scrolldown {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    // Scrolldown light
    &--light {
        color: #fff;
        .icon-svg {
            fill: #fff;
            background-color: transparent !important;
        }
    }
    // Scrolldown double-arrow
    &--double-arrow {
        .scrolldown {
            &__arrow {
                display: flex;
                flex-direction: column;
                .icon-svg {
                    animation: ScrolldownDoubleArrow 2s infinite;
                    animation-delay: 0.2s;
                    & + .icon-svg {
                        animation-delay: unset;
                    }
                }
                & + .scrolldown__txt {
                    margin-top: 20px;
                }
            }
            &__txt {
                font-size: 1.4rem;
                & + .scrolldown__arrow {
                    margin-top: 8px;
                }
            }
        }
    }
    @keyframes ScrolldownDoubleArrow {
        0% {
            transform: translateY(0) rotate(180deg);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translateY(10px) rotate(180deg);
            opacity: 0;
        }
    }
}

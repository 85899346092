
.main-circle-card {
    border-radius: 50% !important;
    position: relative;
    &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
        border-radius: 50%;
    }
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
            .tit {
                color: #fff !important;
            }
        }
    }
    &--1 {
        .tit {
            color: var(--v-secondary-base);
        }
        a {
            &:hover,
            &:focus {
                background-color: var(--v-secondary-base);
                .v-image {
                    content: url(/images/main/circle-card-hover-01.png);
                }
            }
        }
    }
    &--2 {
        .tit {
            color: #009edb;
        }
        a {
            &:hover,
            &:focus {
                background-color: #009edb;
                .v-image {
                    content: url(/images/main/circle-card-hover-02.png);
                }
            }
        }
    }
    &--3 {
        .tit {
            color: var(--v-primary-base);
        }
        a {
            &:hover,
            &:focus {
                background-color: var(--v-primary-base);
                .v-image {
                    content: url(/images/main/circle-card-hover-03.png);
                }
            }
        }
    }
    &--4 {
        .tit {
            color: #0b386a;
        }
        a {
            &:hover,
            &:focus {
                background-color: #0b386a;
                .v-image {
                    content: url(/images/main/circle-card-hover-04.png);
                }
            }
        }
    }
}


.footer {
    background-color: var(--v-grey-darken4);
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
::v-deep {
    .v-btn.v-size--small:not(.v-btn--icon) {
        margin: 0 -12px;
    }
    .v-divider--vertical {
        align-self: center;
        vertical-align: unset;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
        background: transparent;
        input::placeholder,
        i {
            color: #fff !important;
        }
    }
}
.v-divider {
    border-color: rgba(255, 255, 255, 0.2) !important;
}
.vr {
    display: block;
    width: 1px;
    height: 12px;
    background: rgba(255, 255, 255, 0.2);
}
.family-btn {
    position: relative;
    .txt-wrap {
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
            transform: rotate(90deg);
        }
    }
    &__li {
        position: absolute;
        bottom: 180%;
        left: 0;
        width: 100%;
        border: 1px solid var(--v-grey-lighten3);
        background-color: #fff;
        color: var(--v-grey-darken4);
        padding: 10px;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
        padding: 15px 0;
        z-index: 999;
        .v-divider {
            border-block-style: dashed;
            border-color: var(--v-grey-lighten3) !important;
            margin: 10px 0;
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: var(--v-grey-lighten1);
            transition: all 0.1s;
            &:hover,
            &:focus {
                color: var(--v-grey-darken4);
            }
        }
    }
}
.family-btn.on {
    i {
        transform: rotate(-90deg);
    }
    .family-btn__li {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
